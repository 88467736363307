import React, { useEffect, useState } from "react";
import "./styles/LoginForm.css";
import { Form, FormLayout, TextField, Button, Card, Text, InlineError } from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../axios";
import axios from "axios";
import { getUserToken, setUserToken } from "../Utils/useAuth";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({ isError: false, errorMsg: "" });

  const handleEmailChange = (value) => setEmail(value);
  const handlePasswordChange = (value) => setPassword(value);
  const navigate = useNavigate();

  console.log(getUserToken());
  const handleLogin = async (userMail, UserPassword) => {
    const url = baseUrl + "/api/user/login";
    const headers = {
      "Content-Type": "application/json",
    };

    const body = {
      email: userMail,
      password: UserPassword,
    };

    const res = await axios.post(url, body, { headers });
    const resData = await res.data;

    if (resData.code === 200) {
      console.log(resData.data.token);
      setUserToken(resData.data.token);
      navigate("/");
    } else {
      alert(res.data.message);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // if (email.length <= 0 || password.length <= 0) {
    //   setError({ isError: true, errorMsg: "Fields Can't be empty" });
    //   return;
    // } else if (emailRegex.test(email)) {
    //   setError({ isError: true, errorMsg: "Incorrect Email!" });
    //   return;
    // }

    handleLogin(email, password);
  };

  useEffect(() => {
    const user = getUserToken();
    if (user) navigate("/");
    // console.log("hii");
  }, []);

  return (
    <div className="loginCard">
      <Card padding="10">
        <div style={{ paddingBottom: "var(--p-space-2)" }}>
          <Text variant="headingLg" as="h4">
            Login
          </Text>
        </div>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <FormLayout>
            <TextField label="Email" type="email" value={email} onChange={handleEmailChange} required />
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            {/* error message  */}
            {error.isError && <InlineError message={error.errorMsg} fieldID="myFieldID" />}
            <div className="submit_btn">
              <Button submit primary size="large">
                Login
              </Button>
            </div>
          </FormLayout>
        </Form>
      </Card>
    </div>
  );
};

export default LoginForm;
