import React, { useEffect, useState } from "react";
import { Card, Page, VerticalStack, HorizontalGrid, Box, Text, Banner } from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import { ReplayMinor, CircleDownMajor } from "@shopify/polaris-icons";
import SkeletonBody from "../components/SkeletonBody";
import { baseUrl } from "../axios";
import axios from "axios";
import { getUserToken } from "../Utils/useAuth";

const OrderDetail = () => {
  const { orderId } = useParams();
  const [response, setResponse] = useState(null);
  const [isError, setIsError] = useState(false);

  const getOrder = async () => {
    const url = `${baseUrl}/api/order/order-details/${orderId}`;
    const token = getUserToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImV4YW1wbGVAZG9tYWluLmNvbSIsInBhc3N3b3JkIjoicGFzc3dvcmQiLCJpYXQiOjE2ODQxNTIwNTh9.3vtCTUXa8TU55hRzuPB_xjBG9ouPgzzN60ofeh_Y4VI",
    };
    let resData = null;
    try {
      const res = await axios.get(url, { headers });
      resData = await res.data;

      if (res.status === 200 && res.data.error === false) {
        setResponse(resData.data[0]);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else {
        console.log(error);
      }
    }
  };

  // image id check
  const isCustomIdExist = (data) => {
    let res = true;

    for (const lineItem of data.line_items) {
      if (lineItem?.properties.length !== 0) {
        for (const image of lineItem?.properties) {
          if (!(image.name.includes("custom_image_id") && image.value === null)) {
            res = false;
            break;
          }
        }
      } else {
        res = false;
      }
    }

    console.log(res);
    return res;
  };

  // regenerat pdf
  const regeneratePDF = async () => {
    const url = baseUrl + "/api/order/regenerate-pdf";
    const token = getUserToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const body = {
      order_id: orderId,
    };
    let resData = null;

    try {
      const res = await axios.post(url, body, { headers });
      resData = await res.data;

      if (res.status === 200 && res.data.code === 200) {
        alert(resData.message);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getOrder();
  }, []);

  const user = getUserToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) navigate("/");
  }, []);

  return (
    <Page
      breadcrumbs={[{ content: "previous", url: "/" }]}
      title={`#${orderId}`}
      subtitle="Order Detail"
      primaryAction={{
        content: "Download Order PDF",
        icon: CircleDownMajor,
        onAction: () => {
          window.open(response.order_pdf, "_blank");
        },
        disabled: response?.order_pdf ? false : true,
      }}
      secondaryActions={[
        {
          content: "Regenerate PDF",
          icon: ReplayMinor,
          onAction: () => {
            regeneratePDF();
            getOrder();
          },
          // disabled: !(response?.line_items.length > 0 && isCustomIdExist(response) && !response?.order_pdf),
          disabled: false,
        },
      ]}
    >
      {response === null ? (
        <SkeletonBody />
      ) : (
        <HorizontalGrid columns={{ xs: 1, md: "2fr 1fr" }} gap="4">
          <VerticalStack gap="4">
            <Card roundedAbove="sm">
              <Text variant="headingLg" as="h5">
                Product Detail
              </Text>
              <Box border="divider" borderRadius="base" minHeight="1rem" />

              {/* basic data */}
              {response.line_items.length > 0 &&
                response.line_items.map((item, index) => {
                  return (
                    <VerticalStack key={index} gap={10}>
                      <HorizontalGrid columns={3}>
                        <VerticalStack gap={3}>
                          <Text variant="headingMd" as="h5">
                            {item?.title}
                          </Text>
                          <Text variant="headingXs" as="h5">
                            {item?.variant_title}
                          </Text>
                          <Text variant="headingSm" as="h5">
                            SKU : {item?.sku}
                          </Text>
                        </VerticalStack>
                        <Text variant="headingSm" as="h5" alignment="center">
                          {item?.price} x {item?.quantity}
                        </Text>
                        <Text variant="headingSm" as="h5" alignment="center">
                          {item?.price}
                        </Text>
                      </HorizontalGrid>

                      <Box border="divider" borderRadius="base" minHeight="0.5rem" />

                      <VerticalStack>
                        <Text variant="headingSm" as="h6">
                          Location
                        </Text>
                        <p>
                          {item.origin_location?.name}, {item.origin_location?.address1},{" "}
                          {item.origin_location?.city}, {item.origin_location?.zip},{" "}
                          {item.origin_location?.country_code}
                        </p>

                        <Box border="divider" borderRadius="base" minHeight="0.5rem" />
                        { item.custom_message ? (
                            <Text><b>Custom Message:</b> {item.custom_message}</Text>
                        ) :  null }

                        {item?.properties && item?.properties?.length !== 0 ? (
                          item.properties.map((imageItem, index) => {
                            return imageItem?.name === "custom_print_id" ? (
                              <HorizontalGrid columns={2} key={index}>
                                <Text>
                                  <b> {imageItem?.name || "NA"}</b>: {imageItem?.value || "NA"}
                                </Text>
                                <HorizontalGrid columns={1} key={index}>
                                  {imageItem?.value &&
                                    imageItem?.value !== "null" &&
                                    response?.customImage &&
                                    response?.customImage[imageItem.value]?.map((item, index) => {
                                      return (
                                        <HorizontalGrid columns={2} key={index}>
                                          <a href={item?.master} target="_blank" rel="noreferrer">
                                            Master
                                          </a>
                                          <a href={item?.preview} target="_blank" rel="noreferrer">
                                            Preview
                                          </a>
                                        </HorizontalGrid>
                                      );
                                    })}
                                </HorizontalGrid>
                              </HorizontalGrid>
                            ) : (
                              ""
                            );
                          })
                        ) : (
                          <div style={{ margin: "10px 0px" }}>
                            <Banner>
                              <p>Custom Images are not available!</p>
                            </Banner>
                          </div>
                        )}
                      </VerticalStack>
                      <Box border="divider" borderRadius="base" minHeight="2rem" />
                    </VerticalStack>
                  );
                })}
            </Card>
            <Card roundedAbove="sm">
              {/* <VerticalStack gap="4"> */}
              <HorizontalGrid columns={3}>
                <Text variant="headingSm" as="h5">
                  Payment Status : {response?.financial_status?.toUpperCase() || "NA"}
                </Text>
                <Text variant="headingSm" as="h5">
                  Order Status : {response?.fulfillment_status?.toUpperCase() || "NA"}
                </Text>
                <Text variant="headingSm" as="h5">
                  <a href={response?.order_status_url} target="_blank" rel="noreferrer">
                    Order Status
                  </a>
                </Text>
              </HorizontalGrid>
              {/* </VerticalStack> */}
            </Card>
            <Card>
              <Text variant="headingLg" as="h5">
                Customer Detail
              </Text>
              <Box border="divider" borderRadius="base" minHeight="0.5rem" />
              <Text variant="headingSm" as="h5">
                Customer Name : {`${response.customer?.first_name} ${response.customer?.last_name}`}
              </Text>
              <Text variant="headingSm" as="h5">
                Phone :{response.customer.phone == null ? "--" : response.customer?.phone}
              </Text>
              <Text variant="headingSm" as="h5">
                Email :{response.customer?.email == null ? "--" : response.customer?.email}
              </Text>
              <Text variant="headingSm" as="h5">
                Store :{response?.store}
              </Text>
            </Card>
          </VerticalStack>
          <VerticalStack gap={{ xs: "4", md: "2" }}>
            <Card roundedAbove="sm" title="Shipping Address">
              <VerticalStack>
                <Text variant="headingLg" as="h5">
                  Shipping Address
                </Text>
                {/* <Divider /> */}
                <Box border="divider" borderRadius="base" minHeight="0.5rem" />

                <>
                  {response.shipping_address?.first_name + +response.shipping_address?.last_name}
                  <br />
                  {response.shipping_address?.address1}
                  {/* <br />
              {response.shipping_address.address2} */}
                  <br />
                  {response.shipping_address.city}
                  <br />
                  {response.shipping_address.province}
                  <br />
                  {response.shipping_address.country}
                  <br />
                  {response.shipping_address.zip}
                </>
              </VerticalStack>
            </Card>
            <Card roundedAbove="sm" title="Shipping Address">
              <VerticalStack>
                <Text variant="headingLg" as="h5">
                  Billing Address
                </Text>
                {/* <Divider /> */}
                <Box border="divider" borderRadius="base" minHeight="0.5rem" />
                <p>
                  {response.billing_address.first_name + +response.billing_address.last_name}
                  <br />
                  {response.billing_address.address1}
                  {/* <br />
              {response.billing_address.address2} */}
                  <br />
                  {response.billing_address.city}
                  <br />
                  {response.billing_address.province}
                  <br />
                  {response.billing_address.country}
                  <br />
                  {response.billing_address.zip}
                </p>
              </VerticalStack>
            </Card>
          </VerticalStack>
        </HorizontalGrid>
      )}
    </Page>
  );
};

export default OrderDetail;
