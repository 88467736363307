import {
  Layout,
  LegacyCard,
  SkeletonBodyText,
} from "@shopify/polaris";

import React from "react";

function SkeletonBody() {
  return (
    <Layout>
      <Layout.Section>
        <LegacyCard sectioned>
          {/* <SkeletonBodyText /> */}
        </LegacyCard>
        <LegacyCard sectioned>
          <SkeletonBodyText />
          <SkeletonBodyText />
        </LegacyCard>
      </Layout.Section>
    </Layout>
  );
}
export default SkeletonBody;
