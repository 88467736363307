import React, { useState } from "react";

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  const handleError = (error, component) => {
    setHasError(true);

    console.error(error);

    return (
      <div>
        <h1>Something went wrong.</h1>
        <p>Please try again later.</p>
      </div>
    );
  };

  return <div>{hasError ? handleError() : children}</div>;
};

export default ErrorBoundary;
