import { createBrowserRouter } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import App from "./App";
import Orders from "./pages/Orders";
import ResetPassword from "./pages/ResetPassword";
import OrderDetail from "./pages/OrderDetail";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/orders",
    element: <Orders />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "orders/:orderId",
    element: <OrderDetail />,
  },
]);

export default router;
