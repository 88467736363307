import { useNavigate } from "react-router";
import { CircleRightMajor } from "@shopify/polaris-icons";
import { Link } from "react-router-dom";
const { IndexTable, Text, Icon } = require("@shopify/polaris");

const OrderItem = ({
  orderName,
  orderId,
  customerEmail,
  orderStatus,
  paymentStatus,
  imageCount
}) => {

  const navigate = useNavigate();

  return (
    <>
      <IndexTable.Row>
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {orderName}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{customerEmail}</IndexTable.Cell>
        <IndexTable.Cell>{orderStatus}</IndexTable.Cell>
        <IndexTable.Cell>{paymentStatus}</IndexTable.Cell>
        <IndexTable.Cell>{imageCount}</IndexTable.Cell>
        <IndexTable.Cell>
          <Link to={`${orderId}`}>
            <Icon source={CircleRightMajor} color="" />
          </Link>
        </IndexTable.Cell>
      </IndexTable.Row>
    </>
  );
};

export default OrderItem;
