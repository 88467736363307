import React, { useState } from "react";
import "./styles/LoginPage.css";
import "./styles/ResetPassword.css";
import checkLength from "../Utils/checkLength";

import { Card, Text, Form, FormLayout, TextField, Button, InlineError } from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../axios";
import { getUserToken } from "../Utils/useAuth";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");

  const [error, setError] = useState({ isError: false, errorMsg: "" });

  const handleOldPassword = (value) => setOldPassword(value);
  const handleNewPassword = (value) => setNewPassword(value);
  const handleCnfPassword = (value) => setCnfPassword(value);

  const navigate = useNavigate();

  const resetPassword = async () => {
    const url = baseUrl + "/api/user/change-password";

    const token = getUserToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const body = {
      password: oldPassword,
      newPassword1: newPassword,
      newPassword2: cnfPassword,
    };

    let resData = null;

    try {
      const res = await axios.post(url, body, { headers });
      resData = await res.data;

      if (resData?.code === 200) {
        localStorage.clear();
      }
      console.log(res.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else {
        console.log(error);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //for empty fields

    if (checkLength(oldPassword, 0) || checkLength(newPassword, 0) || checkLength(cnfPassword, 0)) {
      setError({ isError: true, errorMsg: "Fields Can't be empty" });
      return;
    }
    // more than 8 char
    else if (checkLength(newPassword, 7)) {
      setError({
        isError: true,
        errorMsg: "Password length should be more than 8 character",
      });
      return;
    }
    //password matching
    else if (newPassword !== cnfPassword) {
      setError({ isError: true, errorMsg: "Passwords don't match" });
      return;
    }

    resetPassword();

    navigate("/");
  };

  return (
    <div className="login_page_container">
      <div className="loginCard">
        <Card padding="10">
          <div style={{ paddingBottom: "var(--p-space-2)" }}>
            <Text variant="headingLg" as="h4">
              Reset Password
            </Text>
          </div>
          <Form>
            <FormLayout>
              <TextField
                label="Old Password"
                type="Password"
                id="oldPassword"
                value={oldPassword}
                onChange={handleOldPassword}
                required
              />

              <TextField
                label="New Password"
                type="Password"
                id="newPassword"
                value={newPassword}
                onChange={handleNewPassword}
                required
              />
              <TextField
                label="Confirm Password"
                type="Password"
                id="cnfPassword"
                value={cnfPassword}
                onChange={handleCnfPassword}
                required
              />

              {/* error message  */}
              {error.isError && <InlineError message={error.errorMsg} fieldID="myFieldID" />}

              <div className="submit_btn">
                <Button primary size="large" onClick={handleSubmit}>
                  Reset
                </Button>
              </div>
            </FormLayout>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ChangePassword;
