import { useEffect } from "react";
import "./App.css";
import { getUserToken } from "./Utils/useAuth";
import { useNavigate } from "react-router";

function App() {
  const user = getUserToken();
  // console.log(user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) navigate("/orders");
    else navigate("/login");
  });

  return <div className="App" style={{ minHeight: "100vh" }}></div>;
}

export default App;
