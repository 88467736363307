import React, { useEffect, useState } from "react";
import OrderItem from "../components/OrderItem";
import { Card, Page, IndexTable } from "@shopify/polaris";
import { Icon } from "@shopify/polaris";
import { PasskeyMajor, StoreMajor, RefreshMajor } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../axios";
import axios from "axios";
import SkeletonBody from "../components/SkeletonBody";
import { Pagination } from "antd";
import { getUserToken } from "../Utils/useAuth";
import { setStore } from "../store";
import { useDispatch, useSelector } from "react-redux";

const Orders = () => {

  const navigate = useNavigate();
  const store = useSelector((state) => state.userStore.value);
  // console.log(store);
  const dispatch = useDispatch();
  // const [store, setStore] = useState("addisonross");

  const [orders, setOrders] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  // console.log(orders);
  // fetch function
  const updateData = async () => {
    setIsLoading(true);
    const url = baseUrl + "/api/order/all-orders";
    const token = getUserToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const body = {
      store: store + ".myshopify.com",
      page: currentPage,
      limit: 15,
    };

    let resData = null;

    try {
      const res = await axios.post(url, body, { headers });
      resData = await res.data;

      if (resData?.code === 200) {
        setOrders(resData.data);
      }
      // console.log(res.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else {
        console.log(error);
      }
    }

    setIsLoading(false);
  };

  // sync data
  const syncData = async () => {
    setIsLoading(true);
    const url = baseUrl + "/api/order/orderSync";

    const token = getUserToken();

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const body = {
      store: store + ".myshopify.com",
    };

    let resData = null;

    try {
      const res = await axios.get(url, body, { headers });
      resData = await res.data;

      if (res.status === 200) {
        updateData();
        alert(resData.message);
      }
    } catch (error) {
      if (error.response) {
        alert("Something went wrong!");
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else {
        console.log(error);
      }
    }

    setIsLoading(false);
  };

  // fetch and update data
  useEffect(() => {
    updateData();
  }, [currentPage, store]);

  // authenticate
  const user = getUserToken();
  useEffect(() => {
    // console.log("dddddd", user);
    if (!user) navigate("/");
  }, []);

  return (
    <Page
      title="Orders"
      subtitle="All Orders Data"
      primaryAction={{
        content: "Sync",
        icon: RefreshMajor,
        onAction: () => syncData(),
      }}
      actionGroups={[
        {
          title: "Reset",
          icon: <Icon source={PasskeyMajor} color="base" />,
          actions: [
            {
              content: "Change Password",
              accessibilityLabel: "Individual action label",
              onAction: () => navigate("/reset-password"),
            },
          ],
        },
        {
          title: `${store}`,
          icon: <Icon source={StoreMajor} color="base" />,
          actions: [
            {
              content: "addisonross",
              onAction: () => {
                dispatch(setStore("addisonross"));
              },
            },
            {
              content: "addisonrossusa",
              onAction: () => {
                dispatch(setStore("addisonrossusa"));
              },
            },
            {
              content: "addisonross-eu",
              onAction: () => {
                dispatch(setStore("addisonross-eu"));
              },
            },
            {
              content: "addisonrosstest-store",
              onAction: () => {
                dispatch(setStore("addisonrosstest-store"));
              },
            },
          ],
        },
      ]}
    >
      {orders === null || isLoading ? (
        <SkeletonBody />
      ) : (
        <Card background="bg-subdued" padding={2}>
          {/* order table */}
          <IndexTable
            resourceName={{
              singular: "order",
              plural: "orders",
            }}
            itemCount={10}
            selectable={false}
            headings={[
              { title: "Order Id" },
              { title: "Customer Email" },
              { title: "Order Status" },
              { title: "Payment Status" },
              { title: "Tags" },
              { title: "Action" },
            ]}
          >
            {orders !== null &&
              orders.orders.map((item, index) => {
                const imageCount = item?.tags || item?.customImage ? "custom_image" : "";
                return (
                  <OrderItem
                    key={item.id}
                    orderName={item.order_number}
                    orderId={item.id}
                    orderStatus={item.fulfillment_status}
                    paymentStatus={item.financial_status}
                    customerEmail={item.email}
                    imageCount={imageCount}
                  />
                );
              })}
          </IndexTable>
        </Card>
      )}

      <div
        style={{
          margin: "20px auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Pagination
          current={currentPage}
          onChange={setCurrentPage}
          pageSize={15}
          total={orders?.total}
          size="small"
          showSizeChanger={false}
        />
      </div>
    </Page>
  );
};

export default Orders;
