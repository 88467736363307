const useAuth = () => {
  if (!!localStorage.getItem("user")) {
    const userToken = localStorage.getItem("user");
    return userToken;
  } else {
    return false;
  }
};
const setUserToken = (token) => {
  localStorage.setItem("addisonRossUserToken", token);
};
const getUserToken = () => {
  const token = localStorage.getItem("addisonRossUserToken");
  return token;
};

const isAuthenticated = () => {
  const token = localStorage.getItem("addisonRossUserToken");

  if (token !== null) {
    return true;
  }

  return false;
};

export { getUserToken, setUserToken, isAuthenticated };
export { useAuth };
