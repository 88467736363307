import { configureStore, createSlice } from "@reduxjs/toolkit";

const userStoreSlice = createSlice({
  name: "userStore",
  initialState: {
    value: "addisonross",
  },
  reducers: {
    setStore: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { setStore } = userStoreSlice.actions;

export default configureStore({
  reducer: {
    userStore: userStoreSlice.reducer,
  },
});
